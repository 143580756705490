import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import axios from 'axios';
import DOMPurify from 'dompurify';

import { ElementHeaderH1, ElementStatus } from '../components/Element';

import { UtilTranslate } from './Util';

function loadstate(key)
{
    let session_value = sessionStorage.getItem(key);
    if (session_value !== null && session_value !== undefined)
        return session_value;

    return null;
}

function loadstateboolean(key)
{
    let session_value = sessionStorage.getItem(key);
    if (session_value === 'true')
        return true;

    return false;
}

function savestate(key, value)
{
    // Save (when value exists)
    if (value !== null && value !== undefined)
        sessionStorage.setItem(key, value);
}

function removestate(key)
{
    sessionStorage.removeItem(key);
}

function nulltoblank(value)
{
    if (value === null)
        return '';

    return value;
}

function nulltofalse(value)
{
    if (value === null)
        return false;

    return value;
}

function select(ip_address, key, value)
{
    // Payload
    const data = {
        'key': key,
        'value': value
    };

    // Post and handle error
    axios.post(ip_address+'/backend/rechargedbatteries/api/select', data).catch(function (error) {
        console.log(error);
    });
}

// Props: debug, ip_address, translations_data, upload_icons
function SectionCalendar(props)
{
    // Configurator
    const [configurator_calendar, configurator_set_calendar] = useState(loadstate('configurator_calendar'));

    useEffect(() => {
        savestate('configurator_calendar', configurator_calendar);
    }, [
        configurator_calendar
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        select(props.ip_address, 'calendar', configurator_calendar);

        navigate('/type');
    }
    
    // Options 2 (Calendar)
    const [options_data, options_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/option2_calendar');
                // <str> option title
                // <int> option value
                // <str> option icon

                options_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);

                // Set default
                if (configurator_calendar === null)
                    configurator_set_calendar(response.data[0][1]);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, configurator_calendar]);

    let options = [];
    if (options_data !== null)
    {
        for (let i = 0; i < options_data.length; i++)
        {
            let buttonclass = 'form-radio-tile';
            if (configurator_calendar === options_data[i][1])
            {
                buttonclass =  'form-radio-tile form-radio-tile-selected';
            }

            options.push(
                <React.Fragment key={i}>
                    <input
                        type="radio"
                        className="form-radio-hidden"
                        id={options_data[i][1]}
                        name="calendar"
                        value={options_data[i][1]}
                        checked={configurator_calendar === options_data[i][1]}
                        onChange={event => {
                            configurator_set_calendar(event.target.value);
                        }} />
                    <label
                        htmlFor={options_data[i][1]}
                        className={buttonclass}
                        style={{flexBasis: Math.floor(100/options_data.length)+'%'}}>
                        <div className="form-radio-tile-icon" style={{backgroundImage: 'url('+props.upload_icons+'/'+options_data[i][2]+')'}}>
                        </div>
                        <div className="form-radio-tile-label noselect">
                            <div className="form-radio-tile-label-text">
                                {options_data[i][0]}
                            </div>
                        </div>
                    </label>
                </React.Fragment>
            );
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { options_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'calendar_title', props.debug)} />
                                
                                <div className="form-label">
                                    {UtilTranslate(props.translations_data, 'calendar_when', props.debug)}
                                </div>
                                
                                <form onSubmit={event => handleSubmit(event)}>
                                    <fieldset
                                        className="form-radio-tile-container">
                                        {options}
                                    </fieldset>
                                    
                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, percentage, translations_data, upload_images
function SectionNavigation(props)
{
    // Image
    const [image_data, image_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    key: 'googlereviews'
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/image', { params });
                // <str> image
                
                image_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error) 
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    return (
        <React.Fragment>
            <section className="navbar-section fixed-top">
                <div className="container">
                    <Navbar variant="light" className="navbar-container">
                        <div className="navbar-title-container">
                            <Link to="https://www.recharged.nl" className="navbar-title" target="_blank"></Link>
                            <div className="d-none d-md-block navbar-title-seperator"></div>
                            <div className="d-none d-md-block navbar-subtitle">{UtilTranslate(props.translations_data, 'title', props.debug)}</div>
                        </div>

                        { image_data !== null &&
                            <div className="navbar-reviews" style={{backgroundImage: 'url('+props.upload_images+'/'+image_data+')'}}></div>
                        }
                    </Navbar>
                </div>
                <ElementStatus
                    percentage={props.percentage} />
            </section>
            <section className="navbar-spacing"></section>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translations_data
function SectionContact(props)
{   
    // Configurator
    const configurator_type = loadstate('configurator_type');
    const [configurator_contact_companyname, configurator_set_contact_companyname] = useState(loadstate('configurator_contact_companyname'));
    const [configurator_contact_name, configurator_set_contact_name] = useState(loadstate('configurator_contact_name'));
    const [configurator_contact_street, configurator_set_contact_street] = useState(loadstate('configurator_contact_street'));
    const [configurator_contact_streetnumber, configurator_set_contact_streetnumber] = useState(loadstate('configurator_contact_streetnumber'));
    const [configurator_contact_postalcode, configurator_set_contact_postalcode] = useState(loadstate('configurator_contact_postalcode'));
    const [configurator_contact_city, configurator_set_contact_city] = useState(loadstate('configurator_contact_city'));
    const [configurator_contact_email, configurator_set_contact_email] = useState(loadstate('configurator_contact_email'));
    const [configurator_contact_phone, configurator_set_contact_phone] = useState(loadstate('configurator_contact_phone'));
    const [configurator_contact_kvk, configurator_set_contact_kvk] = useState(loadstate('configurator_contact_kvk'));
    const [configurator_contact_btw, configurator_set_contact_btw] = useState(loadstate('configurator_contact_btw'));
    const [configurator_contact_legal, configurator_set_contact_legal] = useState(loadstateboolean('configurator_contact_legal'));

    useEffect(() => {
        savestate('configurator_contact_companyname', configurator_contact_companyname);
        savestate('configurator_contact_name', configurator_contact_name);
        savestate('configurator_contact_street', configurator_contact_street);
        savestate('configurator_contact_streetnumber', configurator_contact_streetnumber);
        savestate('configurator_contact_postalcode', configurator_contact_postalcode);
        savestate('configurator_contact_city', configurator_contact_city);
        savestate('configurator_contact_email', configurator_contact_email);
        savestate('configurator_contact_phone', configurator_contact_phone);
        savestate('configurator_contact_kvk', configurator_contact_kvk);
        savestate('configurator_contact_btw', configurator_contact_btw);
        savestate('configurator_contact_legal', configurator_contact_legal);
    }, [
        configurator_contact_companyname,
        configurator_contact_name,
        configurator_contact_street,
        configurator_contact_streetnumber,
        configurator_contact_postalcode,
        configurator_contact_city,
        configurator_contact_email,
        configurator_contact_phone,
        configurator_contact_kvk,
        configurator_contact_btw,
        configurator_contact_legal
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        navigate('/samenvatting');

        event.preventDefault();
    }

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'contact',
                        'legal'
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/content', { params });
                // <list> content text
                
                content_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { content_data !== null && props.translations_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'contact_title', props.debug)} />

                                <div className="content-paragraph" dangerouslySetInnerHTML= {{ __html: DOMPurify.sanitize(content_data[0]) }}></div>

                                <form onSubmit={event => handleSubmit(event)}>
                                    { configurator_type === 'business' &&
                                        <React.Fragment>
                                            <label htmlFor="companyname" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_companyname', props.debug)}<div className="form-label-small-required">*</div></label>
                                            <input
                                                className="form-text"
                                                type="text"
                                                id="companyname"
                                                maxLength="128"
                                                value={nulltoblank(configurator_contact_companyname)}
                                                onChange={event => {
                                                    configurator_set_contact_companyname(event.target.value);
                                                }}
                                                required />
                                        </React.Fragment>
                                    }

                                    <label htmlFor="name" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_name', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="name"
                                        maxLength="128"
                                        value={nulltoblank(configurator_contact_name)}
                                        onChange={event => {
                                            configurator_set_contact_name(event.target.value);
                                        }}
                                        required />

                                    <label htmlFor="street" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_street', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="street"
                                        maxLength="128"
                                        value={nulltoblank(configurator_contact_street)}
                                        onChange={event => {
                                            configurator_set_contact_street(event.target.value);
                                        }}
                                        required />

                                    <label htmlFor="number" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_number', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="streetnumber"
                                        maxLength="128"
                                        value={nulltoblank(configurator_contact_streetnumber)}
                                        onChange={event => {
                                            configurator_set_contact_streetnumber(event.target.value);
                                        }}
                                        required />

                                    <label htmlFor="postalcode" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_postalcode', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="postalcode"
                                        maxLength="128"
                                        value={nulltoblank(configurator_contact_postalcode)}
                                        onChange={event => {
                                            configurator_set_contact_postalcode(event.target.value);
                                        }}
                                        required />

                                    <label htmlFor="city" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_city', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="city"
                                        maxLength="128"
                                        value={nulltoblank(configurator_contact_city)}
                                        onChange={event => {
                                            configurator_set_contact_city(event.target.value);
                                        }}
                                        required />

                                    <label htmlFor="email" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_email', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="email"
                                        maxLength="128"
                                        value={nulltoblank(configurator_contact_email)}
                                        onChange={event => {
                                            configurator_set_contact_email(event.target.value);
                                        }}
                                        required />

                                    <label htmlFor="phone" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_phone', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="phone"
                                        maxLength="128"
                                        value={nulltoblank(configurator_contact_phone)}
                                        onChange={event => {
                                            configurator_set_contact_phone(event.target.value);
                                        }}
                                        required />

                                    { configurator_type === 'business' &&
                                        <React.Fragment>
                                            <label htmlFor="kvk" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_kvk', props.debug)}<div className="form-label-small-required">*</div></label>
                                            <input
                                                className="form-text"
                                                type="text"
                                                id="kvk"
                                                maxLength="128"
                                                value={nulltoblank(configurator_contact_kvk)}
                                                onChange={event => {
                                                    configurator_set_contact_kvk(event.target.value);
                                                }}
                                                required />

                                            <label htmlFor="btw" className="form-label-small">{UtilTranslate(props.translations_data, 'contact_btw', props.debug)}</label>
                                            <input
                                                className="form-text"
                                                type="text"
                                                id="btw"
                                                maxLength="128"
                                                value={nulltoblank(configurator_contact_btw)}
                                                onChange={event => {
                                                    configurator_set_contact_btw(event.target.value);
                                                }} />
                                        </React.Fragment>
                                    }

                                    <div className="form-check form-switch">
                                        <label htmlFor="legal" className="form-label-small">
                                            <input
                                                className="form-check-input form-toggle"
                                                type="checkbox"
                                                id="legal"
                                                checked={nulltofalse(configurator_contact_legal)}
                                                onChange={event => configurator_set_contact_legal(event.target.checked)}
                                                required />
                                            <div className="form-toggle-tex noselect" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[1], { ADD_ATTR: ['target'] }) }}></div>
                                        </label>
                                    </div>

                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'contact_submit', props.debug)}</span></button>
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data
function SectionEnergycontract(props)
{   
    // Configurator
    const [configurator_energycontract, configurator_set_energycontract] = useState(loadstate('configurator_energycontract'));

    useEffect(() => {
        savestate('configurator_energycontract', configurator_energycontract);
    }, [
        configurator_energycontract
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        select(props.ip_address, 'solarpanels_energycontract', configurator_energycontract);

        navigate('/voorkeur');
    }

    // Options 4 (Energycontract)
    const [options_data, options_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/option4_energycontract');
                // <str> option title
                // <int> option value

                options_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let options = [];
    if (options_data !== null)
    {
        for (let i = 0; i < options_data.length; i++)
        {
            options.push(
                <React.Fragment key={i}>
                    <label
                        htmlFor={'energycontract-'+options_data[i][1]}
                        className="form-radio-text-label">
                        
                        <input
                            type="radio"
                            className="form-radio-text-button"
                            id={'energycontract-'+options_data[i][1]}
                            name="energycontract"
                            value={options_data[i][1]}
                            checked={configurator_energycontract === options_data[i][1]}
                            onChange={event => {
                                configurator_set_energycontract(event.target.value);
                            }} />

                        <div className="form-radio-text-input">
                            {options_data[i][0]}
                        </div>
                    </label>
                </React.Fragment>
            );
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        <ElementHeaderH1
                            text={UtilTranslate(props.translations_data, 'energycontract_title', props.debug)} />

                        <form onSubmit={event => handleSubmit(event)}>
                            <div className="form-label">
                                {UtilTranslate(props.translations_data, 'energycontract_type', props.debug)}
                            </div>

                            <fieldset
                                className="form-radio-text-container">
                                {options}
                            </fieldset>

                            { configurator_energycontract !== null && 
                                <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data
function SectionEstimate(props)
{   
    // Configurator
    const [configurator_estimate_persons, configurator_set_estimate_persons] = useState(loadstate('configurator_estimate_persons'));
    const [configurator_estimate_type, configurator_set_estimate_type] = useState(loadstate('configurator_estimate_type'));
    const [configurator_estimate_usage, configurator_set_estimate_usage] = useState(loadstate('configurator_estimate_usage'));
    
    useEffect(() => {
        savestate('configurator_estimate_persons', configurator_estimate_persons);
        savestate('configurator_estimate_type', configurator_estimate_type);
        savestate('configurator_estimate_usage', configurator_estimate_usage);
    }, [
        configurator_estimate_persons,
        configurator_estimate_type,
        configurator_estimate_usage
    ]);

    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        // Save
        savestate('configurator_autofill_usage', configurator_estimate_usage);

        select(props.ip_address, 'autofill_usage', configurator_estimate_usage);

        navigate('/');
    }

    // Options 1 (estimate persons)
    const [options_data1, options_set_data1] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/option1_estimatepersons');
                // <str> option title

                options_set_data1(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let options1 = [];
    options1.push(
        <option disabled value="" key="0"> -- {UtilTranslate(props.translations_data, 'form_select', props.debug)} -- </option>
    );

    if (options_data1 !== null)
    {
        for (let i = 0; i < options_data1.length; i++)
        {
            options1.push(
                <option value={options_data1[i]} key={1+i}>{options_data1[i]}</option>
            );
        }
    }

    // Options 2 (estimate type)
    const [options_data2, options_set_data2] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/option1_estimatetype');
                // <str> option title

                options_set_data2(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let options2 = [];
    options2.push(
        <option disabled value="" key="0"> -- {UtilTranslate(props.translations_data, 'form_select', props.debug)} -- </option>
    );
    
    if (options_data2 !== null)
    {
        for (let i = 0; i < options_data2.length; i++)
        {
            options2.push(
                <option value={options_data2[i]} key={1+i}>{options_data2[i]}</option>
            );
        }
    }

    // Estimate (estimate usage)
    const [estimate_data, estimate_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/option1_estimateusage');
                // <str> type title
                // <str> persons title
                // <int> esimate

                estimate_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    useEffect(() => {
        if (configurator_estimate_persons !== null && configurator_estimate_type !== null && estimate_data !== null)
        {   
            for (let i = 0; i < estimate_data.length; i++)
            {
                if (estimate_data[i][0] === configurator_estimate_type && estimate_data[i][1] === configurator_estimate_persons)
                    configurator_set_estimate_usage(estimate_data[i][2]);
            }
        }
    }, [configurator_estimate_persons, configurator_estimate_type, estimate_data, configurator_set_estimate_usage]);

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        <ElementHeaderH1
                            text={UtilTranslate(props.translations_data, 'estimate_title', props.debug)} />

                        <form onSubmit={event => handleSubmit(event)}>
                            <div className="form-label">
                                {UtilTranslate(props.translations_data, 'estimate_persons', props.debug)}
                            </div>
                            <select
                                className="form-dropdown"
                                name="form_indicationpersons"
                                value={nulltoblank(configurator_estimate_persons)}
                                onChange={event => {
                                    configurator_set_estimate_persons(event.target.value);
                                }} >
                                
                                {options1}
                            </select>

                            <div className="form-label">
                                {UtilTranslate(props.translations_data, 'estimate_type', props.debug)}
                            </div>
                            <select
                                className="form-dropdown"
                                name="form_indicationtype"
                                value={nulltoblank(configurator_estimate_type)}
                                onChange={event => {
                                    configurator_set_estimate_type(event.target.value);
                                }} >
                                
                                {options2}
                            </select>
                            
                            { configurator_estimate_persons !== null && configurator_estimate_type !== null && configurator_estimate_usage !== null && estimate_data !== null && props.translations_data !== null &&
                                <React.Fragment>
                                    <div className="content-info-container" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize('<div class="content-info-icon"></div>'+UtilTranslate(props.translations_data, 'estimate_result', props.debug).replace('$total', configurator_estimate_usage)) }}></div>

                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_autofill', props.debug)}</span></button>
                                </React.Fragment>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data
function SectionOverview(props)
{   
    // Configurator
    const configurator_usage = loadstate('configurator_usage');
    const configurator_custom_usage = loadstate('configurator_custom_usage');
    const configurator_calendar = loadstate('configurator_calendar');
    const configurator_type = loadstate('configurator_type');
    const configurator_solarpanels_present = loadstate('configurator_solarpanels_present');
    const configurator_solarpanels_interest = loadstate('configurator_solarpanels_interest');
    const configurator_solarpanels_inverterbrand = loadstate('configurator_solarpanels_inverterbrand');
    const configurator_energycontract = loadstate('configurator_energycontract');
    const configurator_preference = loadstate('configurator_preference');
    const configurator_meterbox_age = loadstate('configurator_meterbox_age');
    const configurator_meterbox_fases = loadstate('configurator_meterbox_fases');
    const configurator_number = loadstate('configurator_number');
    
    const configurator_contact_companyname = loadstate('configurator_contact_companyname');
    const configurator_contact_name = loadstate('configurator_contact_name');
    const configurator_contact_street = loadstate('configurator_contact_street');
    const configurator_contact_streetnumber = loadstate('configurator_contact_streetnumber');
    const configurator_contact_postalcode = loadstate('configurator_contact_postalcode');
    const configurator_contact_city = loadstate('configurator_contact_city');
    const configurator_contact_email = loadstate('configurator_contact_email');
    const configurator_contact_phone = loadstate('configurator_contact_phone');
    const configurator_contact_kvk = loadstate('configurator_contact_kvk');
    const configurator_contact_btw = loadstate('configurator_contact_btw');

    // Form
    const [form_loading, form_set_loading] = useState(true);
    const [form_error, form_set_error] = useState(false);

    // Overview
    const [overview_data, overview_set_data] = useState(null);
    useEffect(() => {
        const post_overview = async() => {
            try
            {
                let usage;
                if (configurator_usage !== 'custom')
                    usage = configurator_usage;
                else
                    usage = configurator_custom_usage;

                const data = {
                    'name': configurator_contact_name,
                    'street': configurator_contact_street,
                    'streetnumber': configurator_contact_streetnumber,
                    'postalcode': configurator_contact_postalcode,
                    'city': configurator_contact_city,
                    'email': configurator_contact_email,
                    'phone': configurator_contact_phone,
                    'usage': usage,
                    'calendar': configurator_calendar,
                    'type': configurator_type,
                    'solarpanels_present': configurator_solarpanels_present,
                    'energycontract': configurator_energycontract,
                    'preference': configurator_preference,
                    'meterbox_age': configurator_meterbox_age,
                    'meterbox_fases': configurator_meterbox_fases,
                    'number': configurator_number
                };

                if (configurator_type === 'business')
                {
                    Object.assign(data, {
                        'companyname': configurator_contact_companyname,
                        'kvk': configurator_contact_kvk
                    });

                    if (configurator_contact_btw !== '' && configurator_contact_btw !== null)
                    {
                        Object.assign(data, {
                            'btw': configurator_contact_btw
                        });
                    }
                }

                if (configurator_solarpanels_present == 'yes')
                {
                    Object.assign(data, {
                        'solarpanels_inverterbrand': configurator_solarpanels_inverterbrand,
                    });
                }

                if (configurator_solarpanels_present == 'no')
                {
                    Object.assign(data, {
                        'solarpanels_interest': configurator_solarpanels_interest,
                    });
                }

                const response = await axios.post(props.ip_address+'/backend/rechargedbatteries/api/overview', data);
                // <str> calendar
                // <str> type
                // <str> energycontract
                // <str> preference
                // <str> meterbox fases title
                // <int> price incl tax
                // <int> price excl tax
                // <int> tax advantage (21% of price)

                let response_fixed = response.data;

                // Localize number formatting
                response_fixed[5] = response_fixed[5].toString().replace('.', ',');
                response_fixed[6] = response_fixed[6].toString().replace('.', ',');
                response_fixed[7] = response_fixed[7].toString().replace('.', ',');
                
                overview_set_data(response_fixed);
                form_set_loading(false);
                
                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                form_set_loading(false);
                form_set_error(true);

                console.log(error);
            }
        }

        post_overview();
    }, [props.debug, props.ip_address,
    
        configurator_usage, configurator_custom_usage, configurator_calendar, configurator_type, configurator_solarpanels_present, configurator_solarpanels_interest, configurator_solarpanels_inverterbrand, configurator_energycontract, configurator_preference, configurator_meterbox_age, configurator_meterbox_fases, configurator_number, configurator_contact_companyname, configurator_contact_name, configurator_contact_street, configurator_contact_streetnumber, configurator_contact_postalcode, configurator_contact_city, configurator_contact_email, configurator_contact_phone, configurator_contact_kvk, configurator_contact_btw]);
    
    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        form_set_loading(true);
        form_set_error(false);

        const post_conversion = async() => {
            try
            {
                let usage;
                if (configurator_usage !== 'custom')
                    usage = configurator_usage;
                else
                    usage = configurator_custom_usage;
                
                const data = {
                    'name': configurator_contact_name,
                    'street': configurator_contact_street,
                    'streetnumber': configurator_contact_streetnumber,
                    'postalcode': configurator_contact_postalcode,
                    'city': configurator_contact_city,
                    'email': configurator_contact_email,
                    'phone': configurator_contact_phone,
                    'usage': usage,
                    'calendar': configurator_calendar,
                    'type': configurator_type,
                    'solarpanels_present': configurator_solarpanels_present,
                    'energycontract': configurator_energycontract,
                    'preference': configurator_preference,
                    'meterbox_age': configurator_meterbox_age,
                    'meterbox_fases': configurator_meterbox_fases,
                    'number': configurator_number
                };

                if (configurator_type === 'business')
                {
                    Object.assign(data, {
                        'companyname': configurator_contact_companyname,
                        'kvk': configurator_contact_kvk
                    });

                    if (configurator_contact_btw !== '' && configurator_contact_btw !== null)
                    {
                        Object.assign(data, {
                            'btw': configurator_contact_btw
                        });
                    }
                }

                if (configurator_solarpanels_present == 'yes')
                {
                    Object.assign(data, {
                        'solarpanels_inverterbrand': configurator_solarpanels_inverterbrand,
                    });
                }

                if (configurator_solarpanels_present == 'no')
                {
                    Object.assign(data, {
                        'solarpanels_interest': configurator_solarpanels_interest,
                    });
                }

                const response = await axios.post(props.ip_address+'/backend/rechargedbatteries/api/conversion', data);

                if (props.debug === true)
                    console.log(response.data);

                select(props.ip_address, 'conversion', '-');

                navigate('/bedankt');
            }

            catch (error)
            {
                form_set_loading(false);
                form_set_error(true);

                console.log(error);
            }
        }

        post_conversion();
    }

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'overview'
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/content', { params });
                // <list> content text

                content_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { form_loading === true &&
                            <div className="content-paragraph">
                                <div className="form-loading-container">
                                    <div className="form-loading"></div>
                                </div>
                            </div>
                        }

                        { form_loading === false && form_error === true && props.translations_data !== null &&
                            <div className="content-error-container" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(UtilTranslate(props.translations_data, 'form_error', props.debug)) }}>
                            </div>
                        }
                        
                        { content_data !== null && form_loading === false && form_error === false &&
                            <div className="content-overview-container">
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'overview_title', props.debug)} />

                                <div className="content-overview-subcontainer">
                                    <div className="content-overview-subcontainer-header">
                                        <div className="content-overview-title">
                                            {UtilTranslate(props.translations_data, 'overview_yourchoices', props.debug)}
                                        </div>
                                    </div>

                                    <table className="content-overview-table">
                                        <tbody>
                                            <tr>
                                                <td className="content-overview-table-cell-left-top">
                                                    {UtilTranslate(props.translations_data, 'overview_calendar', props.debug)}
                                                </td>
                                                <td className="content-overview-table-cell-right-top">
                                                    {overview_data[0]}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="content-overview-table-cell-left">
                                                    {UtilTranslate(props.translations_data, 'overview_type', props.debug)}
                                                </td>
                                                <td className="content-overview-table-cell-right">
                                                    {overview_data[1]}
                                                </td>
                                            </tr>

                                            { configurator_solarpanels_present === 'yes' &&
                                                <React.Fragment>
                                                    <tr>
                                                        <td className="content-overview-table-cell-left">
                                                            {UtilTranslate(props.translations_data, 'overview_solarpanels', props.debug)}
                                                        </td>
                                                        <td className="content-overview-table-cell-right">
                                                            {UtilTranslate(props.translations_data, 'form_yes', props.debug)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="content-overview-table-cell-left">
                                                            {UtilTranslate(props.translations_data, 'overview_inverterbrand', props.debug)}
                                                        </td>
                                                        <td className="content-overview-table-cell-right">
                                                            {configurator_solarpanels_inverterbrand}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            }

                                            { configurator_solarpanels_present === 'no' &&
                                                <tr>
                                                    <td className="content-overview-table-cell-left">
                                                        {UtilTranslate(props.translations_data, 'overview_solarpanels', props.debug)}
                                                    </td>
                                                    <td className="content-overview-table-cell-right">
                                                        {UtilTranslate(props.translations_data, 'form_no', props.debug)}
                                                    </td>
                                                </tr>
                                            }

                                            <tr>
                                                <td className="content-overview-table-cell-left">
                                                    {UtilTranslate(props.translations_data, 'overview_energycontract', props.debug)}
                                                </td>
                                                <td className="content-overview-table-cell-right">
                                                    {overview_data[2]}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="content-overview-table-cell-left">
                                                    {UtilTranslate(props.translations_data, 'overview_preference', props.debug)}
                                                </td>
                                                <td className="content-overview-table-cell-right">
                                                    {overview_data[3]}
                                                </td>
                                            </tr>
                                            <tr>    
                                                <td className="content-overview-table-cell-left">
                                                    {UtilTranslate(props.translations_data, 'overview_meterbox_fases', props.debug)}
                                                </td>
                                                <td className="content-overview-table-cell-right">
                                                    {overview_data[4]}
                                                </td>
                                            </tr>
                                            <tr>    
                                                <td className="content-overview-table-cell-left-bottom">
                                                    {UtilTranslate(props.translations_data, 'overview_number', props.debug)}
                                                </td>
                                                <td className="content-overview-table-cell-right-bottom">
                                                    { parseInt(configurator_number) === 1 &&
                                                        <React.Fragment>
                                                            1 {UtilTranslate(props.translations_data, 'overview_battery_single', props.debug)}
                                                        </React.Fragment>
                                                    }

                                                    { parseInt(configurator_number) > 1 &&
                                                        <React.Fragment>
                                                            {configurator_number} {UtilTranslate(props.translations_data, 'overview_battery_multiple', props.debug)}
                                                        </React.Fragment>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="content-overview-subcontainer-footer">
                                        {UtilTranslate(props.translations_data, 'overview_capability', props.debug)}

                                        <div className="content-overview-title">
                                            <div className="content-overview-title-icon"></div> {configurator_number*5} kWh vermogen
                                        </div>

                                        {UtilTranslate(props.translations_data, 'overview_indication', props.debug)}

                                        { configurator_type === 'private' &&
                                            <div className="content-overview-price">
                                                <div className="d-block d-sm-none">
                                                    <div className="content-overview-price-title-mobile">
                                                        € {overview_data[5]}
                                                    </div>
                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <div className="content-overview-price-title-desktop">
                                                        € {overview_data[5]}
                                                    </div>
                                                    <div className="content-overview-price-text">
                                                        {UtilTranslate(props.translations_data, 'overview_inbtw', props.debug)}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        { configurator_type === 'business' &&
                                            <div className="content-overview-price">
                                                <div className="d-block d-sm-none">
                                                    <div className="content-overview-price-title-mobile">
                                                        € {overview_data[6]}
                                                    </div>
                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <div className="content-overview-price-title-desktop">
                                                        € {overview_data[6]}
                                                    </div>
                                                    <div className="content-overview-price-text">
                                                        {UtilTranslate(props.translations_data, 'overview_exbtw', props.debug)}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        
                                        { configurator_type === 'private' &&
                                            <div className="content-info-container-inline" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize('<div class="content-info-icon"></div>'+UtilTranslate(props.translations_data, 'overview_kor', props.debug).replace('$cost', overview_data[7])) }}>
                                            </div>
                                        }
                                        
                                        { parseInt(configurator_number) === 1 &&
                                            <div className="content-overview-title">{UtilTranslate(props.translations_data, 'overview_conversion_single', props.debug)}</div>
                                        }

                                        { parseInt(configurator_number) > 1 &&
                                            <div className="content-overview-title">{UtilTranslate(props.translations_data, 'overview_conversion_multiple', props.debug)}</div>
                                        }

                                        <form onSubmit={event => handleSubmit(event)}>
                                            <button onClick = {() => {}} className="form-submit-overview"><span>{UtilTranslate(props.translations_data, 'form_send', props.debug)}</span></button>
                                        </form>

                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                                        
                                        <div className="content-overview-installq-logo"></div>
                                        
                                        <div className="content-overview-installq-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(UtilTranslate(props.translations_data, 'overview_installq', props.debug)) }}>
                                        </div>
                                    </div>
                                </div>

                                <div className="content-info-container">
                                    <div className="content-info-icon"></div>
                                    {UtilTranslate(props.translations_data, 'overview_disclaimer', props.debug)}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data
function SectionMeterbox(props)
{   
    // Configurator
    const [configurator_meterbox_age, configurator_set_meterbox_age] = useState(loadstate('configurator_meterbox_age'));
    const [configurator_meterbox_fases, configurator_set_meterbox_fases] = useState(loadstate('configurator_meterbox_fases'));

    useEffect(() => {
        savestate('configurator_meterbox_age', configurator_meterbox_age);
        savestate('configurator_meterbox_fases', configurator_meterbox_fases);
    }, [
        configurator_meterbox_age,
        configurator_meterbox_fases
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        select(props.ip_address, 'meterbox_age', configurator_meterbox_age);
        select(props.ip_address, 'meterbox_fases', configurator_meterbox_fases);

        navigate('/aantal');
    }

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'meterbox'
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/content', { params });
                // <list> content text
                
                content_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    // Options 6 (meterbox)
    const [options_data1, options_set_data1] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/option6_meterbox_age');
                // <str> option title
                // <str> option value
                
                options_set_data1(response.data);

                if (props.debug === true)
                    console.log(response.data);

                // Set default
                if (configurator_meterbox_age === null)
                    configurator_set_meterbox_age(response.data[0][1]);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, configurator_meterbox_age]);

    let options1 = [];
    if (options_data1 !== null)
    {
        for (let i = 0; i < options_data1.length; i++)
        {
            options1.push(
                <React.Fragment key={i}>
                    <label
                        htmlFor={'meterbox_age-'+options_data1[i][1]}
                        className="form-radio-text-label">
                        
                        <input
                            type="radio"
                            className="form-radio-text-button"
                            id={'meterbox_age-'+options_data1[i][1]}
                            name="meterbox_age"
                            value={options_data1[i][1]}
                            checked={configurator_meterbox_age === options_data1[i][1]}
                            onChange={event => {
                                configurator_set_meterbox_age(event.target.value);
                            }} />

                        <div className="form-radio-text-input">
                            {options_data1[i][0]}
                        </div>
                    </label>
                </React.Fragment>
            );
        }
    }

    // Options 2
    const [options_data2, options_set_data2] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/option6_meterbox_fases');
                // <str> option title
                // <str> option value
                
                options_set_data2(response.data)

                if (props.debug === true)
                    console.log(response.data);

                // Set default
                if (configurator_meterbox_fases === null)
                    configurator_set_meterbox_fases(response.data[0][1]);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, configurator_meterbox_fases]);

    let options2 = [];
    if (options_data2 !== null)
    {
        for (let i = 0; i < options_data2.length; i++)
        {
            options2.push(
                <React.Fragment key={i}>
                    <label
                        htmlFor={'meterbox_fases-'+options_data2[i][1]}
                        className="form-radio-text-label">
                        
                        <input
                            type="radio"
                            className="form-radio-text-button"
                            id={'meterbox_fases-'+options_data2[i][1]}
                            name="meterbox_fases"
                            value={options_data2[i][1]}
                            checked={configurator_meterbox_fases === options_data2[i][1]}
                            onChange={event => {
                                configurator_set_meterbox_fases(event.target.value);
                            }} />

                        <div className="form-radio-text-input">
                            {options_data2[i][0]}
                        </div>
                    </label>
                </React.Fragment>
            );
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { content_data !== null && options_data1 !== null && options_data2 !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'meterbox_title', props.debug)} />

                                <form onSubmit={event => handleSubmit(event)}>
                                    <div className="form-label">
                                        {UtilTranslate(props.translations_data, 'meterbox_age', props.debug)}
                                    </div>
                                    <fieldset
                                        className="form-radio-text-container">
                                        {options1}
                                    </fieldset>

                                    <div className="form-label">
                                        {UtilTranslate(props.translations_data, 'meterbox_fases', props.debug)}
                                    </div>
                                    <fieldset
                                        className="form-radio-text-container">
                                        {options2}
                                    </fieldset>

                                    { content_data !== null &&
                                        <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                                    }

                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data
function SectionNumber(props)
{   
    // Configurator
    const configurator_usage = loadstate('configurator_usage');
    const configurator_custom_usage = loadstate('configurator_custom_usage');
    const [configurator_number, configurator_set_number] = useState(loadstate('configurator_number'));

    useEffect(() => {
        savestate('configurator_number', configurator_number);
    }, [
        configurator_number, configurator_set_number
    ]);

    // Form
    const button_decrease = (event) => {
        if (configurator_number !== '')
            configurator_set_number(Math.max(parseInt(configurator_number)-1, 1));
        else
            configurator_set_number(1);

        event.preventDefault();
    }

    const button_increase = (event) => {
        if (configurator_number !== '')
            configurator_set_number(Math.min(parseInt(configurator_number)+1, 999999));
        else
            configurator_set_number(1);

        event.preventDefault();
    }

    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        select(props.ip_address, 'number', configurator_number);

        navigate('/contact');
    }

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'sessy'
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/content', { params });
                // <list> content text
                
                content_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    // Sessy
    const [sessy_data, sessy_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/sessy');
                // <list1> <str> title
                // <list1> <int> text
                // <list2> <str> title
                // <list2> <str> text

                sessy_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let sessy_advantages = [];
    let sessy_specifications = [];
    if (sessy_data !== null)
    {
        for (let i = 0; i < sessy_data[0].length; i++)
        {
            sessy_advantages.push(
                <React.Fragment key={i}>
                    <div className="content-sessy-title-container">
                        <div className="content-sessy-title-icon"></div>
                        <div className="content-sessy-title-title">{sessy_data[0][i][0]}</div>
                    </div>
                    <div className="content-sessy-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sessy_data[0][i][1]) }}></div>
                </React.Fragment>
            );
        }

        for (let i = 0; i < sessy_data[1].length; i++)
        {
            sessy_specifications.push(
                <React.Fragment key={i}>
                    <tr className="content-sessy-table-row">
                        <td className="content-sessy-table-cell-left">
                            {sessy_data[1][i][0]}
                        </td>
                        <td className="content-sessy-table-cell-right">
                            {sessy_data[1][i][1]}
                        </td>
                    </tr>
                </React.Fragment>
            );
        }
    }

    // Number
    const [number_data, number_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {};
                if (configurator_usage !== 'custom')
                {
                    params = {
                        usage: configurator_usage
                    };
                }
                else
                {
                    params = {
                        usage: configurator_custom_usage
                    };
                }
                
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/number', { params });
                // <int> number

                number_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);

                // Apply pre-set number
                if (configurator_number === null)
                    configurator_set_number(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, configurator_usage, configurator_custom_usage, configurator_number]);

    let recommended = null;
    let indication = null;
    if (number_data !== null && props.translations_data !== null)
    {
        let text = null;
        if (number_data === 1)
        {
            text = UtilTranslate(props.translations_data, 'number_text_single', props.debug);

            if (configurator_usage !== 'custom')
                text = text.replace('$usage', configurator_usage+' kWh');
            else
                text = text.replace('$usage', configurator_custom_usage+' kWh');
            
            text = text.replace('$number', number_data);
        }
        else
        {
            text = UtilTranslate(props.translations_data, 'number_text_multiple', props.debug);

            if (configurator_usage !== 'custom')
                text = text.replace('$usage', configurator_usage+' kWh');
            else
                text = text.replace('$usage', configurator_custom_usage+' kWh');
            
            text = text.replace('$number', number_data);
        }

        recommended = <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}></div>;

        if (configurator_number !== null && configurator_number !== '')
        {
            let kwh = 5;

            if (configurator_number === 1)
            {
                indication = <div className="content-paragraph">
                {UtilTranslate(props.translations_data, 'number_indication_single', props.debug)+' '+(configurator_number*kwh)+' kWh'}</div>
            }
            else
            {
                indication = <div className="content-paragraph">
                {UtilTranslate(props.translations_data, 'number_indication_multiple', props.debug)+' '+(configurator_number*kwh)+' kWh'}</div>
            }
        }
    }
    
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        <ElementHeaderH1
                            text={UtilTranslate(props.translations_data, 'number_title', props.debug)} />

                        { content_data !== null &&
                            <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                        }

                        <img className="content-sessy-image" src="image/photo_sessy.jpg" width="100%" />
                        
                        <div className="content-sessy-container">
                            { sessy_advantages }
                        </div>

                        <table className="content-sessy-table">
                            <tbody>
                                {sessy_specifications}
                            </tbody>
                        </table>

                        { recommended }

                        <div className="form-label">
                            {UtilTranslate(props.translations_data, 'number_amount', props.debug)}
                        </div>

                        <form onSubmit={event => handleSubmit(event)}>
                            <div className="form-number-container">
                                <button 
                                    className="form-number-button-left"
                                    onClick = {event => button_decrease(event)}>
                                    -
                                </button>

                                <label className="form-number-label">
                                    <input
                                        type="number"
                                        className="form-number-label-input"
                                        id="number"
                                        name="number"
                                        min="1"
                                        max="999999"
                                        value={nulltoblank(configurator_number)}
                                        onChange={event => {
                                            configurator_set_number(event.target.value);
                                        }} 
                                        required />
                                </label>

                                <button 
                                    className="form-number-button-right"
                                    onClick = {event => button_increase(event)}>
                                    +
                                </button>
                            </div>

                            { indication }
                                        
                            { configurator_number !== null && configurator_number !== '' &&
                                <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data, upload_icons
function SectionPreference(props)
{   
    // Configurator
    const configurator_energycontract = loadstate('configurator_energycontract');
    const [configurator_preference, configurator_set_preference] = useState(loadstate('configurator_preference'));

    useEffect(() => {
        savestate('configurator_preference', configurator_preference);
    }, [
        configurator_preference, configurator_set_preference
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        select(props.ip_address, 'preference', configurator_preference);
        
        navigate('/meterkast');
    }

    // Options 5 (preference)
    const [options_data, options_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {};
                params = {
                    energycontract: configurator_energycontract
                }

                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/option5_preference', { params });
                // <str> energycontract title
                // <list> <str> option title
                // <list> <int> option value
                // <list> <str> option icon
                // <list> <bool> possible
                // <list> <bool> recommend
                // <list> <str> advice

                options_set_data(response.data)

                if (props.debug === true)
                    console.log(response.data);

                // Set default
                if (configurator_preference === null)
                {
                    configurator_set_preference(response.data[1][0][1]);
                    console.log(1);
                    console.log(response.data[1][0][1]);
                }
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, configurator_energycontract, configurator_preference]);

    let options = [];
    if (options_data !== null)
    {
        for (let i = 0; i < options_data[1].length; i++)
        {
            let buttonclass = 'form-radio-preferencetile noselect';
            if (configurator_preference === options_data[1][i][1])
            {
                buttonclass = 'form-radio-preferencetile form-radio-preferencetile-selected noselect';
            }

            options.push(
                <React.Fragment key={i}>
                    <label
                        htmlFor={options_data[1][i][1]}
                        className={buttonclass}>

                        <input
                            type="radio"
                            className="form-radio-hidden"
                            id={options_data[1][i][1]}
                            name="type"
                            value={options_data[1][i][1]}
                            checked={configurator_preference === options_data[1][i][1]}
                            onChange={event => {
                                configurator_set_preference(event.target.value);
                            }} />

                        <div className="form-radio-preferencetile-blank">
                            <div className="form-radio-preferencetile-title-container">
                                <div className="form-radio-preferencetile-title-icon" style={{backgroundImage: 'url('+props.upload_icons+'/'+options_data[1][i][2]+')'}}></div>
                                <div className="form-radio-preferencetile-title">{options_data[1][i][0]}</div>
                            </div>
                            
                            { options_data[1][i][4] &&
                                <div className="form-radio-preferencetile-recommend">{UtilTranslate(props.translations_data, 'preference_recommended', props.debug)}</div>
                            }

                            { options_data[1][i][1] !== 'dontknow' &&
                                <div className="form-radio-preferencetile-footer">
                                    { options_data[1][i][3] === true &&
                                        <div className="form-radio-preferencetile-recommend-yes">
                                            <div className="form-radio-preferencetile-recommend-yes-icon"></div>
                                            {UtilTranslate(props.translations_data, 'preference_possible', props.debug)}
                                        </div>
                                    }

                                    { options_data[1][i][3] === false &&
                                        <div className="form-radio-preferencetile-recommend-no">
                                            <div className="form-radio-preferencetile-recommend-no-icon"></div>
                                            {UtilTranslate(props.translations_data, 'preference_notpossible', props.debug)}
                                        </div>
                                    }

                                    { options_data[1][i][4] !== null &&
                                        <React.Fragment>
                                            <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(options_data[1][i][5]) }}></div>
                                        </React.Fragment>
                                    }
                                </div>
                            }
                        </div>
                    </label>
                </React.Fragment>
            );
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                        { options_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'preference_title', props.debug)} />
                                
                                <div className="content-paragraph">{UtilTranslate(props.translations_data, 'preference_contract', props.debug)}</div>

                                <div className="content-info-container"><div className="content-info-icon"></div>{UtilTranslate(props.translations_data, 'preference_energycontract', props.debug)} <b>{options_data[0]}</b>.</div>
                                
                                <div className="form-label">
                                    {UtilTranslate(props.translations_data, 'preference_text', props.debug)}
                                </div>

                                <form onSubmit={event => handleSubmit(event)}>
                                    <fieldset
                                        className="form-radio-preferencetile-container">
                                        {options}
                                    </fieldset>
                                    
                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: translations_data
function SectionPrevious(props)
{   
    return (
        <section>
            <div className="container">
                <div className="row">
                    <Link
                        className="navbar-previous"
                        to={props.previous}>
                        <div className="navbar-previous-icon"></div>
                        {UtilTranslate(props.translations_data, 'form_previous', props.debug)}
                    </Link>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data
function SectionSolarpanels(props)
{   
    // Configurator
    const [configurator_solarpanels_present, configurator_set_solarpanels_present] = useState(loadstate('configurator_solarpanels_present'));
    const [configurator_solarpanels_interest, configurator_set_solarpanels_interest] = useState(loadstate('configurator_solarpanels_interest'));
    const [configurator_solarpanels_inverterbrand, configurator_set_solarpanels_inverterbrand] = useState(loadstate('configurator_solarpanels_inverterbrand'));

    useEffect(() => {
        savestate('configurator_solarpanels_present', configurator_solarpanels_present);
        savestate('configurator_solarpanels_interest', configurator_solarpanels_interest);
        savestate('configurator_solarpanels_inverterbrand', configurator_solarpanels_inverterbrand);
    }, [
        configurator_solarpanels_present,
        configurator_solarpanels_interest,
        configurator_solarpanels_inverterbrand
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        select(props.ip_address, 'solarpanels_present', configurator_solarpanels_present);
        select(props.ip_address, 'solarpanels_interest', configurator_solarpanels_interest);
        select(props.ip_address, 'solarpanels_inverterbrand', configurator_solarpanels_inverterbrand);

        navigate('/energiecontract');
    }
    
    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'solarpanels',
                        'nosolarpanels'
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/content', { params });
                // <list> content text
                
                content_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        <ElementHeaderH1
                            text={UtilTranslate(props.translations_data, 'solarpanels_title', props.debug)} />
                        
                        <form onSubmit={event => handleSubmit(event)}>
                            <div className="form-label">
                                {UtilTranslate(props.translations_data, 'solarpanels_present', props.debug)}
                            </div>

                            <fieldset
                                className="form-radio-text-container">
                                
                                <label
                                    htmlFor="solarpanels-present-yes"
                                    className="form-radio-text-label">
                                    
                                    <input
                                        type="radio"
                                        className="form-radio-text-button"
                                        id="solarpanels-present-yes"
                                        name="solarpanels_present"
                                        value="yes"
                                        checked={configurator_solarpanels_present === 'yes'}
                                        onChange={event => {
                                            configurator_set_solarpanels_present(event.target.value);
                                        }} />

                                    <div className="form-radio-text-input">
                                        {UtilTranslate(props.translations_data, 'form_yes', props.debug)}
                                    </div>
                                </label>
                                <label
                                    htmlFor="solarpanels-present-no"
                                    className="form-radio-text-label">
                                    
                                    <input
                                        type="radio"
                                        className="form-radio-text-button"
                                        id="solarpanels-present-no"
                                        name="solarpanels_present"
                                        value="no"
                                        checked={configurator_solarpanels_present === 'no'}
                                        onChange={event => {
                                            configurator_set_solarpanels_present(event.target.value);
                                        }} />

                                    <div className="form-radio-text-input">
                                        {UtilTranslate(props.translations_data, 'form_no', props.debug)}
                                    </div>
                                </label>
                            </fieldset>

                            { configurator_solarpanels_present === 'yes' &&
                                <React.Fragment>
                                    <div className="form-label">
                                        {UtilTranslate(props.translations_data, 'solarpanels_inverterbrand', props.debug)}
                                    </div>
                                    
                                    <label htmlFor="name" className="form-label-small">{UtilTranslate(props.translations_data, 'form_brand', props.debug)}<div className="form-label-small-required">*</div></label>
                                    <input
                                        className="form-text"
                                        type="text"
                                        id="name"
                                        maxLength="128"
                                        value={nulltoblank(configurator_solarpanels_inverterbrand)}
                                        onChange={event => {
                                            configurator_set_solarpanels_inverterbrand(event.target.value);
                                        }}
                                        required />
                                        
                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                </React.Fragment>
                            }

                            { configurator_solarpanels_present === 'no' &&
                                <React.Fragment>
                                    { content_data !== null &&
                                        <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[1]) }}></div>
                                    }

                                    <div className="form-label">
                                        {UtilTranslate(props.translations_data, 'solarpanels_interest', props.debug)}
                                    </div>

                                    { content_data !== null &&
                                        <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                                    }

                                    <fieldset
                                        className="form-radio-text-container">
                                        
                                        <label
                                            htmlFor="solarpanels-interest-yes"
                                            className="form-radio-text-label">
                                            
                                            <input
                                                type="radio"
                                                className="form-radio-text-button"
                                                id="solarpanels-interest-yes"
                                                name="solarpanels_interest"
                                                value="yes"
                                                checked={configurator_solarpanels_interest === 'yes'}
                                                onChange={event => {
                                                    configurator_set_solarpanels_interest(event.target.value);
                                                }} />

                                            <div className="form-radio-text-input">
                                                {UtilTranslate(props.translations_data, 'form_yes', props.debug)}
                                            </div>
                                        </label>
                                        <label
                                            htmlFor="solarpanels-interest-no"
                                            className="form-radio-text-label">
                                            
                                            <input
                                                type="radio"
                                                className="form-radio-text-button"
                                                id="solarpanels-interest-no"
                                                name="solarpanels_interest"
                                                value="no"
                                                checked={configurator_solarpanels_interest === 'no'}
                                                onChange={event => {
                                                    configurator_set_solarpanels_interest(event.target.value);
                                                }} />

                                            <div className="form-radio-text-input">
                                                {UtilTranslate(props.translations_data, 'form_no', props.debug)}
                                            </div>
                                        </label>
                                    </fieldset>

                                    { configurator_solarpanels_interest !== null &&
                                        <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                    }
                                </React.Fragment>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data, upload_icons
function SectionStart(props)
{   
    // Configurator
    const configurator_autofill_usage = loadstate('configurator_autofill_usage');
    const [configurator_usage, configurator_set_usage] = useState(loadstate('configurator_usage'));
    const [configurator_custom_usage, configurator_set_custom_usage] = useState(loadstate('configurator_custom_usage'));

    useEffect(() => {
        savestate('configurator_usage', configurator_usage);
        savestate('configurator_custom_usage', configurator_custom_usage);
    }, [
        configurator_usage,
        configurator_custom_usage
    ]);

    // Form
    const [usage_minimum, set_usage_minimum] = useState(0);

    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        // Failsafe. It's possible states have not been saved, do so now
        savestate('configurator_usage', configurator_usage);
        savestate('configurator_custom_usage', configurator_custom_usage);

        if (configurator_custom_usage === '-')
            select(props.ip_address, 'usage', configurator_usage);
        else
            select(props.ip_address, 'usage', configurator_custom_usage);

        navigate('/plaatsing');
    }

    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'start',
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/content', { params });
                // <list> content text
                
                content_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    // Options
    const [options_data, options_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/option1_usage');
                // <str> option title
                // <int> option value_max

                options_set_data(response.data)
                
                if (props.debug === true)
                    console.log(response.data);

                if (configurator_autofill_usage === null)
                {
                    // Set default
                    if (configurator_usage === null)
                    {
                        if (props.debug === true)
                            console.log('Usage: set default');

                        configurator_set_usage(response.data[0][1]);
                        configurator_set_custom_usage('-');
                    }
                }
                else
                {
                    if (props.debug === true)
                        console.log('Usage: applying autofill');
                    
                    // Apply autofill
                    let autofill = false;
                    for (let i = 0; i < response.data.length; i++)
                    {
                        if (parseInt(configurator_autofill_usage) < response.data[i][1])
                        {
                            configurator_set_usage(response.data[i][1]);
                            configurator_set_custom_usage('-');
                            autofill = true;
                            break;
                        }
                    }

                    if (autofill === false)
                    {
                        configurator_set_usage('custom');
                        configurator_set_custom_usage(configurator_autofill_usage);
                    }

                    removestate('configurator_autofill_usage');
                }
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, configurator_usage, configurator_autofill_usage]);

    let options = [];
    if (options_data !== null)
    {
        for (let i = 0; i < options_data.length; i++)
        {
            if (i < options_data.length-1)
                options.push(<option value={options_data[i][1]} key={i}>{options_data[i][0]}</option>);
            else
                options.push(<option value="custom" key={i}>{options_data[i][0]}</option>);
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { content_data !== null && options_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'start_title', props.debug)} />

                                { content_data !== null &&
                                    <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                                }

                                <form onSubmit={event => handleSubmit(event)}>
                                    <label htmlFor="form_usage" className="form-label">{UtilTranslate(props.translations_data, 'start_usage', props.debug)}</label>
                                    <select
                                        className="form-dropdown"
                                        name="form_usage"
                                        id="form_usage"
                                        value={nulltoblank(configurator_usage)}
                                        onChange={event => {
                                            configurator_set_usage(event.target.value);
                                            configurator_set_custom_usage('-');
                                            
                                            if (event.target.value === 'custom')
                                            {
                                                configurator_set_custom_usage(options_data[options_data.length-1][1]);
                                                set_usage_minimum(options_data[options_data.length-1][1]);
                                            }
                                        }} >
                                        
                                        {options}
                                    </select>

                                    <Link to="/inschatting">
                                        <div className="form-link">
                                            {UtilTranslate(props.translations_data, 'start_estimate', props.debug)}
                                        </div>
                                    </Link>

                                    { configurator_usage === 'custom' &&
                                        <React.Fragment>
                                            <label htmlFor="form_custom_usage" className="form-label-small">{UtilTranslate(props.translations_data, 'start_custom_usage', props.debug)}<div className="form-label-small-required">*</div></label>
                                            <input
                                                className="form-text"
                                                name="form_custom_usage"
                                                id="form_custom_usage"
                                                type="number"
                                                min={usage_minimum}
                                                max="100000000"
                                                step="1"
                                                value={nulltoblank(configurator_custom_usage)}
                                                autoComplete="off"
                                                onChange={event => configurator_set_custom_usage(event.target.value)}
                                                required />
                                        </React.Fragment>
                                    }

                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                </form>

                                <div className="form-label">
                                    {UtilTranslate(props.translations_data, 'start_footer_title', props.debug)}
                                </div>

                                <div className="content-bulletpoints">
                                    <div className="content-bulletpoint-container">
                                        <div className="content-bulletpoint-icon-info"></div>
                                        <div className="content-bulletpoint-text">
                                            {UtilTranslate(props.translations_data, 'start_bulletpoint_info', props.debug)}
                                        </div>
                                    </div>

                                    <div className="content-bulletpoint-container">
                                        <div className="content-bulletpoint-icon-custom"></div>
                                        <div className="content-bulletpoint-text">
                                            {UtilTranslate(props.translations_data, 'start_bulletpoint_custom', props.debug)}
                                        </div>
                                    </div>

                                    <div className="content-bulletpoint-container">
                                        <div className="content-bulletpoint-icon-fast"></div>
                                        <div className="content-bulletpoint-text">
                                            {UtilTranslate(props.translations_data, 'start_bulletpoint_fast', props.debug)}
                                        </div>
                                    </div>
                                </div>

                                <div className="content-footer">
                                    <div className="content-footer-icon"></div>
                                    {UtilTranslate(props.translations_data, 'start_footer', props.debug)}
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data, upload_images
function SectionThankyou(props)
{
    // Content
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: [
                        'thankyou',
                    ]
                };
                
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/content', { params });
                // <list> content text
                
                content_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    // Crosssell
    const [crosssell_data, crosssell_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/crosssell');
                // <list> crosssells
                // <list> <str> title
                // <list> <str> url
                // <list> <str> image_photo

                crosssell_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    let crosssell = [];
    if (crosssell_data !== null)
    {
        for (let i = 0; i < crosssell_data.length; i++)
        {
            crosssell.push(
                <React.Fragment key={i}>
                    <Link to={crosssell_data[i][1]} target="_blank">
                        <div className="content-crosssell-subcontainer" style={{backgroundImage: 'url('+props.upload_images+'/'+crosssell_data[i][2]+')'}}>
                            <div className="content-crosssell-text">
                                {crosssell_data[i][0]}
                            </div>

                            <div className="content-crosssell-chevron"></div>
                        </div>
                    </Link>
                </React.Fragment>
            );
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xxl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-4">
                        { content_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'thankyou_title', props.debug)} />

                                <div className="content-paragraph" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>

                                <div className="content-crosssell-container">
                                    <div className="form-label">{UtilTranslate(props.translations_data, 'thankyou_crosssell', props.debug)}</div>

                                    {crosssell}
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, translations_data, upload_icons
function SectionType(props)
{
    // Configurator
    const [configurator_type, configurator_set_type] = useState(loadstate('configurator_type'));

    useEffect(() => {
        savestate('configurator_type', configurator_type);
    }, [
        configurator_type, configurator_set_type
    ]);

    // Form
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();

        select(props.ip_address, 'type', configurator_type);
        
        navigate('/zonnepanelen');
    }

    // Options 3 (type)
    const [options_data, options_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/rechargedbatteries/api/option3_type');
                // <str> option title
                // <int> option value
                // <str> option icon

                options_set_data(response.data)

                if (props.debug === true)
                    console.log(response.data);

                // Set default
                if (configurator_type === null)
                    configurator_set_type(response.data[0][1]);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, configurator_type]);

    let options = [];
    if (options_data !== null)
    {
        for (let i = 0; i < options_data.length; i++)
        {
            let buttonclass = 'form-radio-tile';
            if (configurator_type === options_data[i][1])
            {
                buttonclass =  'form-radio-tile form-radio-tile-selected';
            }

            options.push(
                <React.Fragment key={i}>
                    <input
                        type="radio"
                        className="form-radio-hidden"
                        id={options_data[i][1]}
                        name="type"
                        value={options_data[i][1]}
                        checked={configurator_type === options_data[i][1]}
                        onChange={event => {
                            configurator_set_type(event.target.value);
                        }} />
                    <label
                        htmlFor={options_data[i][1]}
                        className={buttonclass}
                        style={{flexBasis: Math.floor(100/options_data.length)+'%'}}>
                        <div className="form-radio-tile-icon" style={{backgroundImage: 'url('+props.upload_icons+'/'+options_data[i][2]+')'}}>
                        </div>
                        <div className="form-radio-tile-label noselect">
                            <div className="form-radio-tile-label-text">
                                {options_data[i][0]}
                            </div>
                        </div>
                    </label>
                </React.Fragment>
            );
        }
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-2 col-lg-3 col-xl-4">
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                        { options_data !== null &&
                            <React.Fragment>
                                <ElementHeaderH1
                                    text={UtilTranslate(props.translations_data, 'type_title', props.debug)} />
                                
                                <div className="form-label">
                                    {UtilTranslate(props.translations_data, 'type_type', props.debug)}
                                </div>

                                <form onSubmit={event => handleSubmit(event)}>
                                    <fieldset
                                        className="form-radio-tile-container">
                                        {options}
                                    </fieldset>
                                    
                                    <button onClick = {() => {}} className="form-submit"><span>{UtilTranslate(props.translations_data, 'form_next', props.debug)}</span></button>
                                </form>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export { SectionCalendar, SectionContact, SectionEnergycontract, SectionEstimate, SectionOverview, SectionMeterbox, SectionNavigation, SectionNumber, SectionPreference, SectionPrevious, SectionSolarpanels, SectionStart, SectionThankyou, SectionType }