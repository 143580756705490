import React from 'react';

import { SectionCalendar, SectionContact, SectionEnergycontract, SectionEstimate, SectionOverview, SectionMeterbox, SectionNumber, SectionPreference, SectionPrevious, SectionSolarpanels, SectionStart, SectionThankyou, SectionType } from './Section';

function PageCalendar(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/' 
                translations_data={props.translations_data} />
            
            <SectionCalendar
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data}
                upload_icons={props.upload_icons} />
        </React.Fragment>
    );
}

function PageContact(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/aantal'
                translations_data={props.translations_data} />
            
            <SectionContact
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    );
}

function PageEnergycontract(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/zonnepanelen'
                translations_data={props.translations_data} />

            <SectionEnergycontract
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    )
}

function PageEstimate(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/'
                translations_data={props.translations_data} />

            <SectionEstimate
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    )
}

function PageOverview(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/contact' 
                translations_data={props.translations_data} />

            <SectionOverview
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    );
}

function PageMeterbox(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/voorkeur' 
                translations_data={props.translations_data} />

            <SectionMeterbox
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    );
}

function PageNumber(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/meterkast' 
                translations_data={props.translations_data} />

            <SectionNumber
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    );
}

function PagePreference(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/energiecontract' 
                translations_data={props.translations_data} />

            <SectionPreference
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data}
                upload_icons={props.upload_icons} />
        </React.Fragment>
    );
}

function PageSolarpanels(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/type' 
                translations_data={props.translations_data} />

            <SectionSolarpanels
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />
        </React.Fragment>
    );
}

function PageStart(props)
{
    return (
        <React.Fragment>
            <SectionStart
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data} />

            <div className="content-copyright">
                ©{new Date().getFullYear()}. Applicatie door <a className="content-copyright-link" href="https://thissen.ai" target="_blank" rel="noreferrer">Thissen AI</a>®
            </div>
        </React.Fragment>
    );
}

function PageThankyou(props)
{
    return (
        <SectionThankyou
            debug={props.debug}
            ip_address={props.ip_address}
            translations_data={props.translations_data}
            upload_images={props.upload_images} />
    );
}

function PageType(props)
{
    return (
        <React.Fragment>
            <SectionPrevious
                previous='/plaatsing' 
                translations_data={props.translations_data} />

            <SectionType
                debug={props.debug}
                ip_address={props.ip_address}
                translations_data={props.translations_data}
                upload_icons={props.upload_icons} />
        </React.Fragment>
    );
}

export { PageCalendar, PageContact, PageEnergycontract, PageEstimate, PageOverview, PageMeterbox, PageNumber, PagePreference, PageSolarpanels, PageStart, PageThankyou, PageType }