function UtilTranslate(translations, key, debug)
{
    let translation = translations[key];

    if (debug === true)
    {
        if (translation !== undefined)
            return translation;
        else
            return key;
    }

    if (translation !== undefined)
        return translation;
    else
        return '';
}

export { UtilTranslate }